export const accordionData = [
    {
      title: 'Rev I',
      date :'2 FEB 2024',
      content: "Increase Headlight Brightness by 10% , Added Ablity to Modify Speed limitfrom Display",
    },
    {
      title: 'Rev H',
      date :'15 JAN 2024',
      content: "Bug Fixes , Firmware Security Update ,BLE optimization",
    },
    {
      title: 'Rev G',
      date :'12 NOV 2023',
      content: "Drivers Update , Display Theme , Auto Drive Mode",
    },
    {
      title: 'Rev F',
      date :'12 OCT 2023',
      content: "Bug Fixes , Cosmetic Changes",
    },
    {
      title: 'Rev E',
      date :'13 SEPT 2023',
      content: "Optimiaztion of Engine , Connected Parts Drivers Updates",
    },
    {
      title: 'Rev D',
      date :'9 JUN 2023',
      content: " Auto Lock Mode , Anti theft update",
    }
  ];