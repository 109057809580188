import axiosClient from "./axiosClient";

export function getLatestFirmware(deviceId){
    let url = process.env.REACT_APP_latestfirmwareurl +'/'+deviceId+'/true';
    return axiosClient.get(url);
}

export function authorizeUpdate(firmwareUpdateRequest){
   let url =process.env.REACT_APP_authorizeupdateurl;
   return axiosClient.post(url,firmwareUpdateRequest);
}

export function getFileHistory(deviceESN){
    let url =process.env.REACT_APP_getfilehistory+'/'+deviceESN;
    return axiosClient.get(url);
 }

