import { Link } from "react-router-dom";
import useBreadcrumbs from "use-react-router-breadcrumbs";
function Breadcrumb() {
  const routes = [
    {
      path: "/",
      breadcrumb: "Lookup",
    },
    {
      path: "/equipmentdetails",
      breadcrumb: "Equipment Details",
    },
    {
      path: "/equipmentdetails/firmwareupdatehistory",
      breadcrumb: "Firmware Update History",
    },
    {
      path: "/equipmentdetails/firmwarerevisions",
      breadcrumb: "Other Firmware Revisions",
    },
  ];
  const breadcrumbs = useBreadcrumbs(routes);
  return (
    <div>
      {/* <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          <li className="breadcrumb-item">
            <a href="/#lookup">Look Up</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Equipment Details
          </li>
        </ol>
      </nav> */}
      <nav aria-label="breadcrumb">
        <ol className="breadcrumb">
          {breadcrumbs.map(({ match, breadcrumb }) => (
            <li key={match.pathname} className="breadcrumb-item">
              <Link to={match.pathname}>{breadcrumb}</Link>
            </li>
          ))}
        </ol>
      </nav>
    </div>
  );
}

export default Breadcrumb;
