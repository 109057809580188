import "./FirmwareRevisions.css";
import { useState } from "react";
import { accordionData } from "../../services/OtherFirmware";
import Breadcrumb from "../global/Breadcrumb";
import { useSelector } from "react-redux";
function FirmwareRevisions() {
  const data = useSelector((state) => state.equipment);
  // State to show/hide accordion
  const [activeIndex, setActiveIndex] = useState(-1);
  const Accordion = ({
    title,
    date,
    content,
    index,
    activeIndex,
    onAccordionClick,
  }) => {
    const isActive = index === activeIndex;

    return (
      <div className="accordion-item" key={title}>
        <div
          className="accordion-title accordian-header"
          onClick={() => onAccordionClick(index)}
        >
          <div className="column">{title}</div>
          <div className="column">{date}</div>

          <div>{isActive ? "-" : "+"}</div>
        </div>
        {isActive && (
          <div className="accordion-content">
            <h4>
              <u>Release Notes</u>
            </h4>
            <ul>
              {content.split(",").map((line) => (
                <li>{line}</li>
              ))}
            </ul>
            <br></br>
            <br></br>
            <button type="button" className="btn right-btn">
              Update to this Revision
            </button>
          </div>
        )}
      </div>
    );
  };
  const handleAccordionClick = (index) => {
    setActiveIndex(index === activeIndex ? -1 : index);
  };
  return (
    <div className="container">
      <Breadcrumb></Breadcrumb>
      <h1>Other Firmware Revision</h1>
      <table className="table">
        <thead>
          {/* <tr>
    <th scope="col"></th>
    <th scope="col"></th>
  </tr> */}
        </thead>
        <tbody>
          <tr>
            <th scope="row">Brand </th>
            <td>{data.brand}</td>
          </tr>
          <tr>
            <th scope="row">Model Number</th>
            <td>{data.modelNumber}</td>
          </tr>
          <tr>
            <th scope="row">Serial Number</th>
            <td>{data.serialNumber}</td>
          </tr>
          <tr>
            <th scope="row">Equipment Description</th>
            <td>{data.description}</td>
          </tr>
        </tbody>
      </table>
      <div className="accordion">
        <div className="accordion-item accordian-header">
          <div className="column">Revison</div>
          <div className="column"> Release Date</div>
        </div>
        {accordionData.map((item, index) => (
          <Accordion
            key={index}
            title={item.title}
            date={item.date}
            content={item.content}
            index={index}
            activeIndex={activeIndex}
            onAccordionClick={handleAccordionClick}
          />
        ))}
      </div>
    </div>
  );
}

export default FirmwareRevisions;
