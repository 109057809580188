import logo from '../../assets/images/TTC-black-logo.png';
function Header() {
   const loginRedirect=process.env.REACT_APP_login_url;
   return ( 
<div className="header">
<nav className="navbar navbar-inverse">
  <div className="container-fluid">
    <div className="navbar-header">
      <a className="navbar-brand" href="/#lookup">
        <img  src={logo} alt="Toro" logo="" className='logo'>     
        </img>
        </a>
    </div>
    <ul className="nav navbar-nav navbar-right">
      <li><a href={loginRedirect}><span className="glyphicon glyphicon-log-out sign-out"></span> Sign Out</a></li>
    </ul>
  </div>
</nav>
</div>
)};

export default Header;