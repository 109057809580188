import Breadcrumb from "../global/Breadcrumb";
import "./FirmwareUpdateHistory.css";
import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { getFirmwareUpdateHistory } from "../../services/UpdateHistoryService";
function FirmwareUpdateHistory(props) {
  const [FirmwareHistorydata, setFirmwareHistorydata] = useState([]);
  const [isInitialRender, setIsInitialRender] = useState(true);
  const data = useSelector((state) => state.equipment);
  useEffect(() => {
    if (isInitialRender) {
      let res = getFirmwareUpdateHistory("xcxc", "xvxc");
      console.log(res);
      setFirmwareHistorydata(res);
      setIsInitialRender(false);
    }
  }, [isInitialRender]);
  return (
    <div className="container">
      <Breadcrumb></Breadcrumb>
      <h1>Firmware Update History</h1>
      <table className="table hidden-mobile">
        <thead>
          {/* <tr>
      <th scope="col"></th>
      <th scope="col"></th>
    </tr> */}
        </thead>
        <tbody>
          <tr>
            <th scope="row">Brand </th>
            <td>{data.brand}</td>
          </tr>
          <tr>
            <th scope="row">Model Number</th>
            <td>{data.modelNumber}</td>
          </tr>
          <tr>
            <th scope="row">Serial Number</th>
            <td>{data.serialNumber}</td>
          </tr>
          <tr>
            <th scope="row">Equipment Description</th>
            <td>{data.description}</td>
          </tr>
        </tbody>
      </table>
      <table className="minimalistBlack">
        <thead>
          <tr>
            <th>Date and Time</th>
            <th>From Rev</th>
            <th>To Rev</th>
            <th>Event/Action</th>
          </tr>
        </thead>

        <tbody>
          {FirmwareHistorydata.map((item, idx) => (
            <tr key={item.id}>
              <td>{item.DateTime}</td>
              <td>{item.FromRev}</td>
              <td>{item.TORev}</td>
              <td>{item.Event_Action}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}
export default FirmwareUpdateHistory;
