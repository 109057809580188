
export function getFirmwareUpdateHistory(modelNumber,serialNumber){

    let UpdateHistory=[{
        "id": 1,
        "DateTime": "2/13/2023",
        "FromRev": "Rev A",
        "TORev": "Rev B",
        "Event_Action": "Previous Rev Authorized By Mark Smith"
      }, {
        "id": 2,
        "DateTime": "3/6/2023",
        "FromRev": "Rev C",
        "TORev": "Rev B",
        "Event_Action": "Machine Successfully Updated Firmware"
      }, {
        "id": 3,
        "DateTime": "3/26/2023",
        "FromRev": "Rev A",
        "TORev": "Rev B",
        "Event_Action": "Machine Update in Progress"
      }, {
        "id": 4,
        "DateTime": "7/28/2023",
        "FromRev": "Rev A",
        "TORev": "Rev B",
        "Event_Action": "Update Pending Operator Initiation"
      }, {
        "id": 5,
        "DateTime": "2/23/2023",
        "FromRev": "Rev D",
        "TORev": "Rev B",
        "Event_Action": "Previous Rev Authorized By Mark Smith"
      }, {
        "id": 6,
        "DateTime": "7/7/2023",
        "FromRev": "Rev A",
        "TORev": "Rev C",
        "Event_Action": "Update Canceled By Mark Smith"
      }, {
        "id": 7,
        "DateTime": "3/26/2023",
        "FromRev": "Rev B",
        "TORev": "Rev B",
        "Event_Action": "Previous Rev Authorized By Mark Smith"
      }, {
        "id": 8,
        "DateTime": "12/15/2023",
        "FromRev": "Rev B",
        "TORev": "Rev A",
        "Event_Action": "Machine Successfully Updated Firmware"
      }, {
        "id": 9,
        "DateTime": "5/25/2023",
        "FromRev": "Rev C",
        "TORev": "Rev A",
        "Event_Action": "Machine Update in Progress"
      }, {
        "id": 10,
        "DateTime": "7/19/2023",
        "FromRev": "Rev A",
        "TORev": "Rev A",
        "Event_Action": "Update Canceled By Mark Smith"
      }];

    return UpdateHistory;
}