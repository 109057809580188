function Footer() {
    return (
        <div className="row">
            <div className="col-md-12">
                <footer className="edge footer">
                    <div className="row">
                        <div className="col-md-6">
                            Copyright © 2023 The Toro Company. All Rights Reserved.
                        </div>
                        <div className="col-md-6">
                            <ul>
                                <li><a href={process.env.REACT_APP_Footer_CopyrightPolicy} target="_blank" rel="noopener noreferrer">DMCA/Copyright Policy</a></li>
                                <li><a href={process.env.REACT_APP_Footer_EULA} target="_blank" rel="noopener noreferrer">EULA</a></li>
                                <li><a href={process.env.REACT_APP_Footer_TermsofUse} target="_blank" rel="noopener noreferrer">Terms of Use</a></li>
                                <li><a href={process.env.REACT_APP_Footer_PrivacyPolicy} target="_blank" rel="noopener noreferrer">Privacy Policy</a></li>
                            </ul>

                        </div>
                    </div>
                </footer>
            </div>
        </div>

    )
};

export default Footer;