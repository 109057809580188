import './App.css';
import Footer from './components/global/Footer';
import Lookup from './components/lookup/LookUp';
import Header from './components/global/Header';
import {Routes, Route } from 'react-router-dom';
import UpdateHistory from './components/firmwareupdatehistory/FirmwareUpadateHistory';
import EquipmentDetails from './components/equipmentdetails/EquipmentDetails';
import FirmwareRevisions from './components/otherfirmwarerevisions/FirmwareRevisions'
import HealthCheck from './components/global/Health';
function App() {
  return (
    <div className="App">

      <Header></Header>
      <Routes>
        <Route path="/" element={ <Lookup/> } />
        <Route path="equipmentdetails" element={ <EquipmentDetails/> } />
        <Route path="/equipmentdetails/firmwareupdatehistory" element={ <UpdateHistory/> } />
        <Route path="/equipmentdetails/firmwarerevisions" element={ <FirmwareRevisions/> } />
        <Route path="/healthchecks" element={ <HealthCheck/> } />
      </Routes>
      <Footer></Footer>
    </div>
  );
}

export default App;
