import Axios from "axios";
const axiosClient = Axios.create({
  baseURL: process.env.REACT_APP_apibaseurl ,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json',
    'Ocp-Apim-Subscription-Key': process.env.REACT_APP_apikey 
  }
});

axiosClient.interceptors.request.use(
  (config) => {
    let token = localStorage.getItem('token')
    if (token !=null) {
      config.headers['authorization']=token.replace("#id_token=","Bearer ");
    }
    return config;
  },
  (error) => {
     console.log("request error", error);
    return Promise.reject(error);
  }
);
    
axiosClient.interceptors.response.use(
          function (response) {
            return response;
          }, 
          function (error) {
            let res = error.response;
            if (res.status === 401) {
              window.location.href = process.env.REACT_APP_login_url;
            }
            console.error("Looks like there was a problem. Status Code: " + res.status);
            return Promise.reject(error.response.status);
          }
        );


 export default axiosClient
 