import { useLocation } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import "./EquipmentDetails.css";
import Breadcrumb from "../global/Breadcrumb";
import LoadingSpinner from "../global/LoadingSpinner";
import { getEquipmentDetails } from "../../services/MDWService";
import { getLatestFirmware } from "../../services/FUS_Service";
import { authorizeUpdate } from "../../services/FUS_Service";
import { getFileHistory } from "../../services/FUS_Service";
import { useNavigate } from "react-router-dom";
import { addEquipmentDetails } from "../../slice/equipmentSlice";
import { useDispatch } from 'react-redux'
import { useSelector } from "react-redux";

function EquipmentDetails() {
  const data = useSelector((state) => state.equipment);
  let modelNumber= data.modelNumber;
  let serialNumber =data.serialNumber;
  const location = useLocation();
  console.log("location", location);
  const [equipmentdata, setEquipmentData] = useState("Loading...");
  const [firmwareData, setFirmwareData] = useState("Loading...");
  const [isLoading, setIsLoading] = useState(false);
  const [jobStatus, setJobStatus] = useState("None");
  // //Child Page data
  // const [equipmentdetails, setEquipmentDetailsData] = useState("");
  const dispatch = useDispatch();
  const firmwareupdateRequest = {
    esn: equipmentdata.deviceEsn,
    fileName: firmwareData.description,
    description: equipmentdata.description,
    version: firmwareData.displayVersionNumber,
  };

  const navigate = useNavigate();
  const redirectToUpdateHistory = () => {
    let datastate = {
      modelNumber: modelNumber,
      serialNumber: serialNumber,
      brand: "Toro",
      description: equipmentdata.description,
    };
    dispatch(addEquipmentDetails(datastate))
    navigate("/equipmentdetails/firmwareupdatehistory")
  };

  const redirectToOtherFirmwares = () => {
    let datastate = {
      modelNumber: modelNumber,
      serialNumber: serialNumber,
      brand: "Toro",
      description: equipmentdata.description,
    };
    dispatch(addEquipmentDetails(datastate))
    navigate("/equipmentdetails/firmwarerevisions")
  };
  const getFirmwareStatus = () => {
    if (
      equipmentdata.currentFirmwareVersion == firmwareData.displayVersionNumber
    )
      return "Machine Up to Date";
    else {
      if (jobStatus == "PENDING" || jobStatus == "IN_PROGRESS")
        return "Update Pending";
      else return "Update Available";
    }
  };
  const UpdateFirmwareHandler = (e) => {
    e.currentTarget.disabled = true;
    authorizeUpdate(firmwareupdateRequest)
      .then((result) => {
        console.log(result.data);
        getFileHistory(firmwareupdateRequest.esn)
          .then((result) => {
            console.log(result.data.status);
            setJobStatus(result.data.status);
          })
          .catch((error) => setJobStatus("None"));
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => { 
    setIsLoading(true);
    getEquipmentDetails(modelNumber, serialNumber)
      .then((result) => {
        console.log(result.data);
       // result.data.equipment.deviceEsn = demoESN;
        setEquipmentData(result.data.equipment);
        getFileHistory(result.data.equipment.deviceEsn)
          .then((result) => {
            console.log(result.data.status);
            setJobStatus(result.data.status);
          })
          .catch((error) => setJobStatus("None"));
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        navigate("/#ERR1");
      },[modelNumber]);

    getLatestFirmware(32)
      .then((result) => {
        console.log(result.data);
        setFirmwareData(result.data[0]);
      })
      .catch((error) => console.log(error));
  }, [modelNumber,navigate,serialNumber]);

  return (
    <div className="container">
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          <>
            <Breadcrumb></Breadcrumb>
            <h1>Equipment Details</h1>
            <table className="table hidden-mobile">
              <thead>
                {/* <tr>
      <th scope="col"></th>
      <th scope="col"></th>
    </tr> */}
              </thead>
              <tbody>
                <tr>
                  <th scope="row">Model Number</th>
                  <td>{modelNumber}</td>
                </tr>
                <tr>
                  <th scope="row">Serial Number</th>
                  <td>{serialNumber}</td>
                </tr>
                <tr>
                  <th scope="row">Equipment Description</th>
                  <td>{equipmentdata.description}</td>
                </tr>
              </tbody>
            </table>

            <div className="hidden-mobile">
              <div className="panel panel-info">
                <div className="panel-heading">Firmware Status</div>
                <div className="panel-body">
                  <table className="table">
                    <thead></thead>
                    <tbody>
                      <tr>
                        <th scope="row">Status</th>
                        <td>{getFirmwareStatus()}</td>
                        <td></td>
                      </tr>
                      <tr>
                        <th scope="row">Currently Installed</th>
                        <td>Rev {equipmentdata.currentFirmwareVersion}</td>
                        <td>
                          <span onClick={() => redirectToUpdateHistory()}>Firmware Update History</span>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Latest Available</th>
                        <td>Rev {firmwareData.displayVersionNumber} </td>
                        <td>
                          <span onClick={() => redirectToOtherFirmwares()}>Other Firmware Revisions</span>
                        </td>
                      </tr>
                      <tr>
                        <th scope="row">Description</th>
                        <td>{firmwareData.description} </td>
                        <td> </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="mobile">
              <div className="panel panel-info white-spacing">
                <label>Model Number</label>
                <p>{modelNumber}</p>
                <label>Serial Number</label>
                <p>{serialNumber}</p>
                <label>Equipment Description</label>
                <p>{equipmentdata.description}</p>
              </div>
              <h1>Firmware Status</h1>
              <div className="panel panel-info">
                <div className="white-spacing">
                  <label>Status</label>
                  <p>{getFirmwareStatus()}</p>
                  <label>Currently Installed</label>
                  <p className="firmware-version">
                    Rev {equipmentdata.currentFirmwareVersion}
                  </p>
                  <span onClick={() => redirectToUpdateHistory()}>Firmware Update History</span>
                </div>
                <div className="white-spacing">
                  <label>Latest Available</label>
                  <p className="firmware-version">
                    Rev {firmwareData.displayVersionNumber}
                  </p>
                  <label>Description</label>
                  <p className="firmware-version">{firmwareData.description}</p>
                  <span onClick={() => redirectToOtherFirmwares()}>Other Firmware Revisions</span>
                </div>
              </div>
            </div>
            <br></br>
            <br></br>
            <button
              className="btn btn-danger"
              onClick={UpdateFirmwareHandler}
              disabled={
                equipmentdata.currentFirmwareVersion ==
                firmwareData.displayVersionNumber
              }
            >
              AUTHORIZE UPDATE
            </button>
            <br></br>
            <br></br>
            <a
              className="disabled link"
              data-toggle="modal"
              data-target="#notAvailable"
            >
              Schedule Authorize Update
            </a>
          </>

          <br></br>
        </>
      )}
      <br></br>
    </div>
  );
}

export default EquipmentDetails;
