import { useNavigate } from "react-router-dom";
import "./LookUp.css";
import { useState } from "react";
import { useEffect } from "react";
import LoadingSpinner from "../global/LoadingSpinner";
import { LookUpError } from "../../constant/ValidationMessages";
import { InvalidModelSerialError } from "../../constant/ValidationMessages";
import { addEquipmentDetails } from "../../slice/equipmentSlice";
import { useDispatch } from "react-redux";
function Lookup() {
  const [data, setData] = useState({ modelNumber: null, serialNumber: null });
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const loginRedirect = process.env.REACT_APP_login_url;
  const dispatch = useDispatch();
  const handleChange = (event) => {
    let value = event.target.value;
    let name = event.target.name;

    setData((data) => {
      return {
        ...data, // Spread Operator
        [name]: value,
      };
    });
  };

  const validateAll = () => {
    if (
      data.modelNumber != null &&
      data.modelNumber.trim() !== "" &&
      data.serialNumber != null &&
      data.serialNumber.trim() !== ""
    )
      return true;
    else setErrorMessage(LookUpError);
    return false;
  };
  const navigate = useNavigate();
  const clickHandler = (e) => {
    e.preventDefault();

    const isValid = validateAll();

    if (!isValid) {
      return false;
    }
    let datastate = {
      modelNumber: data.modelNumber,
      serialNumber: data.serialNumber,
      brand: "Toro",
      description: "",
    };
    dispatch(addEquipmentDetails(datastate));
    navigate("/equipmentdetails");
  };
  useEffect(() => {
    if (
      !localStorage.getItem("token") ||
      localStorage.getItem("token") === null ||
      localStorage.getItem("token") === ""
    ) {
      const token = window.location.hash;
      if (!token.includes("token")) {
        if (token.includes("ERR1")) {
          setLoading(true);
          setErrorMessage(InvalidModelSerialError);
        } else {
          window.location.replace(loginRedirect);
        }
      } else {
        // eslint-disable-next-line
        setLoading(true);
        if (token.includes("token")) {
          localStorage.setItem("token", token);
        }
      }
    } else {
      setLoading(true);
      const hash = window.location.hash;
      if (hash.includes("ERR1")) {
        setErrorMessage(InvalidModelSerialError);
      }
    }
  }, [loginRedirect]);

  return (
    <>
      <h1 className="title"> Equipment Lookup </h1>
      {loading && (
        <div className="container-fluid border border lookup">
          <div className="mb-3">
            To look up equipment Firmware Status, enter the model and serial
            number
          </div>

          <form>
            <div className="mb-3">
              <label
                htmlFor="formGroupModelNumberInput"
                className="form-label required"
              >
                Model Number
              </label>
              <input
                type="text"
                name="modelNumber"
                className="form-control"
                id="formGroupModelNumberInput"
                placeholder="Enter Model Number"
                onChange={handleChange}
              ></input>
            </div>
            <div className="mb-3">
              <label
                htmlFor="formGroupSerialNumberInput"
                className="form-label required"
              >
                Serial Number
              </label>
              <input
                type="text"
                name="serialNumber"
                className="form-control"
                id="formGroupSerialNumberInput"
                placeholder="Enter Serial Number"
                onChange={handleChange}
              ></input>
            </div>
            <div className="mb-3">
              <p className="form-label error-message">{errorMessage} </p>
            </div>
            <button
              type="submit"
              className="btn btn-danger"
              onClick={clickHandler}
            >
              LOOK UP
            </button>
          </form>
        </div>
      )}

      {!loading && (
        <div className="d-flex justify-content-center">
          <LoadingSpinner />
        </div>
      )}
    </>
  );
}

export default Lookup;
